import ForgotPassword from "pages/ForgotPassword";
// import User from "pages/Utils/User";

// Dashboard
import Dashboard from "pages/Dashboard";

// Master Data Menu
import PossibilityLevel from "pages/MasterData/PossibilityLevel";
import RiskAmount from "pages/MasterData/RiskAmount";
import RiskCategory from "pages/MasterData/RiskCategory";
import RiskLevel from "pages/MasterData/RiskLevel";
import OrganizationalGoals from "pages/MasterData/OrganizationalGoals";
import IKUTargetDetails from "pages/MasterData/IKUTargetDetails";
import ImpactArea from "pages/MasterData/ImpactArea";
import ImpactLevel from "pages/MasterData/ImpactLevel";

// Piagam Manajemen Risiko
import RiskManagementCharter from "pages/RiskManagementCharter/List";
import CreateRiskManagementCharter from "pages/RiskManagementCharter/Create";
import EditRiskManagementCharter from "pages/RiskManagementCharter/Edit";
import DetailRiskManagementCharter from "pages/RiskManagementCharter/Detail";

//Dokumen Pendukung
import ContextFormulation from "pages/MRSupportingDocuments/ContextFormulation";
import ContextFormulationForm from "pages/MRSupportingDocuments/ContextFormulation/Form";
import ContextFormulationDetail from "pages/MRSupportingDocuments/ContextFormulation/Detail";

import RiskList from "pages/MRSupportingDocuments/RiskList";

// Risk Profile - Admin
import RiskProfileAdmin from "pages/MRSupportingDocuments/RiskProfile/Admin/List";
import CreateRiskProfile from "pages/MRSupportingDocuments/RiskProfile/Admin/Create";
import UpdateAdmin from "pages/MRSupportingDocuments/RiskProfile/Admin/Update";
import DetailAdmin from "pages/MRSupportingDocuments/RiskProfile/Admin/Detail";

// Risk Profile - Staff
import RiskProfileStaff from "pages/MRSupportingDocuments/RiskProfile/Staff/List";
import CreateKadivProbis from "pages/MRSupportingDocuments/RiskProfile/Staff/Create";
import DetailKadivProbis from "pages/MRSupportingDocuments/RiskProfile/Staff/Detail";
import UpdateKadivProbis from "pages/MRSupportingDocuments/RiskProfile/Staff/Update";

// Prioritas Risiko
import RiskPriority from "pages/MRSupportingDocuments/RiskPriority/List";
import RiskPriorityDetail from "pages/MRSupportingDocuments/RiskPriority/Detail";

// Manual IRU - Admin
import AdminManualIruList from "pages/MRSupportingDocuments/IRUManual/Admin/List";
import AdminManualIruCreate from "pages/MRSupportingDocuments/IRUManual/Admin/Create";
import AdminManualIruDetail from "pages/MRSupportingDocuments/IRUManual/Admin/Detail";

// Manual IRU - Staff
import StaffManualIruList from "pages/MRSupportingDocuments/IRUManual/Staff/List";
import StaffManualIruCreate from "pages/MRSupportingDocuments/IRUManual/Staff/Create";
import StaffManualIruDetail from "pages/MRSupportingDocuments/IRUManual/Staff/Detail";

// MitigationPlan - Admin
import AdminMitigationList from "pages/MRSupportingDocuments/MitigationPlan/Admin/List";
import AdminMitigationCreate from "pages/MRSupportingDocuments/MitigationPlan/Admin/Form";
import AdminMitigationDetail from "pages/MRSupportingDocuments/MitigationPlan/Admin/Detail";

// MitigationPlan - Staff
import StaffMitigationList from "pages/MRSupportingDocuments/MitigationPlan/Staff/List";
import StaffMitigationCreate from "pages/MRSupportingDocuments/MitigationPlan/Staff/Form";
import StaffMitigationDetail from "pages/MRSupportingDocuments/MitigationPlan/Staff/Detail";

//Laporan Triwulan
import QuarterlyReport from "pages/QuarterlyReport";

//Agenda Pembahasan
import AdminDiscussionAgenda from "pages/DiscussionAgenda/Admin";
import StaffDiscussionAgenda from "pages/DiscussionAgenda/Staff";

//Riwayat Manajemen Risiko
import RiskManagementHistory from "pages/RiskManagementHistory";
import UnderDevelopment from "pages/UnderDevelopment";
import RiskHistory from "pages/RiskManagementHistory";
import RiskHistoryDetail from "pages/RiskManagementHistory/detail";
import QuarterlyReportForm from "pages/QuarterlyReport/form";
import { Navigate } from "react-router";

const routesName = [
  //Dashboard
  {
    key: "",
    routeType: "private",
    path: "/",
    element: () => <Navigate to="/dashboard" replace />,
    exact: true,
    type: "canView",
  },

  {
    key: "dashboard",
    routeType: "private",
    path: "/dashboard",
    element: Dashboard,
    exact: true,
    type: "canView",
  },

  //Master Data Menu
  {
    key: "probability-level",
    routeType: "private",
    path: "/level-kemungkinan",
    element: PossibilityLevel,
    exact: true,
    type: "canView",
  },
  {
    key: "risk-category",
    routeType: "private",
    path: "/kategori-risiko",
    element: RiskCategory,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "impact-area",
    routeType: "private",
    path: "/area-dampak",
    element: ImpactArea,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "risk-level",
    routeType: "private",
    path: "/data-level-risiko",
    element: RiskLevel,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "risk-size",
    routeType: "private",
    path: "/data-besaran-risiko",
    element: RiskAmount,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "oragnization-target",
    routeType: "private",
    path: "/sasaran-organisasi",
    element: OrganizationalGoals,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "iku-target",
    routeType: "private",
    path: "/rincian-target-iku",
    element: IKUTargetDetails,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  {
    key: "impact-level",
    routeType: "private",
    path: "/level-dampak",
    element: ImpactLevel,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  //Piagam Manajemen Risiko
  {
    key: "risk-management-charter",
    routeType: "private",
    path: "/piagam-manajemen-risiko",
    element: RiskManagementCharter,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  {
    key: "risk-management-charter",
    routeType: "private",
    path: "/piagam-manajemen-risiko/create",
    element: CreateRiskManagementCharter,
    // element: UnderDevelopment,
    exact: true,
  },

  {
    key: "risk-management-charter",
    routeType: "private",
    path: "/piagam-manajemen-risiko/edit/:id",
    element: EditRiskManagementCharter,
    // element: UnderDevelopment,
    exact: true,
  },
  {
    key: "risk-management-charter",
    routeType: "private",
    path: "/piagam-manajemen-risiko/detail/:id",
    element: DetailRiskManagementCharter,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  //Dokumen Pendukung
  {
    key: "risk-list",
    routeType: "private",
    path: "/daftar-risiko",
    element: RiskList,
    exact: true,
    type: "canView",
  },
  {
    key: "context-formula",
    routeType: "private",
    path: "/perumusan-konteks",
    element: ContextFormulation,
    exact: true,
    type: "canView",
  },

  {
    key: "context-formula",
    routeType: "private",
    path: "/perumusan-konteks/create",
    element: ContextFormulationForm,
    exact: true,
  },

  {
    key: "context-formula",
    routeType: "private",
    path: "/perumusan-konteks/:id",
    element: ContextFormulationDetail,
    exact: true,
    type: "canView",
  },

  {
    key: "context-formula",
    routeType: "private",
    path: "/perumusan-konteks/edit/:id",
    element: ContextFormulationForm,
    exact: true,
  },

  {
    key: "risk-profile-admin",
    routeType: "private",
    path: "/profil-risiko-admin",
    element: RiskProfileAdmin,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  {
    key: "risk-profile-staff",
    routeType: "private",
    path: "/profil-risiko-staff",
    element: RiskProfileStaff,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  {
    key: "risk-profile-admin",
    routeType: "private",
    path: "/profil-risiko-admin/create-admin/:id",
    element: CreateRiskProfile,
    exact: true,
  },
  {
    key: "risk-profile-admin",
    routeType: "private",
    path: "/profil-risiko-admin/detail-admin/:id",
    element: DetailAdmin,
    exact: true,
    type: "canView",
  },

  {
    key: "risk-profile-admin",
    routeType: "private",
    path: "/profil-risiko-admin/update-admin/:id",
    element: UpdateAdmin,
    exact: true,
  },

  {
    key: "risk-profile-staff",
    routeType: "private",
    path: "/profil-risiko-staff/detail-kadiv-probis/:id",
    element: CreateKadivProbis,
    exact: true,
    type: "canView",
  },

  {
    key: "risk-profile-staff",
    routeType: "private",
    path: "/profil-risiko-staff/create-kadiv-probis/:id",
    element: CreateKadivProbis,
    exact: true,
  },

  // {
  //   key: "risk-profile-staff",
  //   routeType: "private",
  //   path: "/profil-risiko-staff/update-kadiv-probis/:id",
  //   element: UpdateKadivProbis,
  //   exact: true,
  // },

  // Prioritas Risiko

  {
    key: "risk-priority",
    routeType: "private",
    path: "/prioritas-risiko",
    element: RiskPriority,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },
  
  {
    key: "risk-priority",
    routeType: "private",
    path: "/prioritas-risiko/detail/:id",
    element: RiskPriorityDetail,
    // element: UnderDevelopment,
    exact: true,
    type: "canView",
  },

  // Manual IRU

  {
    key: "manual-iru-admin",
    routeType: "private",
    path: "/manual-iru-admin",
    element: AdminManualIruList,
    exact: true,
    type: "canView",
  },

  {
    key: "manual-iru-admin",
    routeType: "private",
    path: "/manual-iru-admin/create/:id",
    element: AdminManualIruCreate,
    exact: true,
  },

  {
    key: "manual-iru-admin",
    routeType: "private",
    path: "/manual-iru-admin/:id",
    element: AdminManualIruDetail,
    exact: true,
    type: "canView",
  },

  {
    key: "manual-iru-staff",
    routeType: "private",
    path: "/manual-iru-staff",
    element: StaffManualIruList,
    exact: true,
    type: "canView",
  },

  {
    key: "manual-iru-staff",
    routeType: "private",
    path: "/manual-iru-staff/create/:id",
    element: StaffManualIruCreate,
    exact: true,
  },

  {
    key: "manual-iru-staff",
    routeType: "private",
    path: "/manual-iru-staff/:id",
    element: StaffManualIruDetail,
    exact: true,
    type: "canView",
  },

  // Mitigation Plan

  {
    key: "mitigation-plan-admin",
    routeType: "private",
    path: "/rencana-mitigasi-admin",
    element: AdminMitigationList,
    exact: true,
    type: "canView",
  },

  {
    key: "mitigation-plan-admin",
    routeType: "private",
    path: "/rencana-mitigasi-admin/create/:id",
    element: AdminMitigationCreate,
    // element: UnderDevelopment,
    exact: true,
  },

  {
    key: "mitigation-plan-admin",
    routeType: "private",
    path: "/rencana-mitigasi-admin/:id",
    element: AdminMitigationDetail,
    exact: true,
    type: "canView",
  },

  {
    key: "mitigation-plan-staff",
    routeType: "private",
    path: "/rencana-mitigasi-staff",
    element: StaffMitigationList,
    exact: true,
    type: "canView",
  },

  {
    key: "mitigation-plan-staff",
    routeType: "private",
    path: "/rencana-mitigasi-staff/create/:id",
    element: StaffMitigationCreate,
    // element: UnderDevelopment,
    exact: true,
  },

  {
    key: "mitigation-plan-staff",
    routeType: "private",
    path: "/rencana-mitigasi-staff/:id",
    element: StaffMitigationDetail,
    exact: true,
    type: "canView",
  },

  //Laporan Triwulan MR
  {
    key: "quater-report",
    routeType: "private",
    path: "/laporan-triwulan",
    element: QuarterlyReport,
    exact: true,
    type: "canView",
  },
  {
    key: "quater-report",
    routeType: "private",
    path: "/laporan-triwulan/form/:id",
    element: QuarterlyReportForm,
    exact: true,
  },
  {
    key: "quater-report",
    routeType: "private",
    path: "/laporan-triwulan/detail/:id",
    element: QuarterlyReportForm,
    exact: true,
  },

  //Agenda Pembahasan
  {
    key: "agenda",
    routeType: "private",
    path: "/agenda-pembahasan-admin",
    element: AdminDiscussionAgenda,
    exact: true,
    type: "canView",
  },

  {
    key: "agenda-staff",
    routeType: "private",
    path: "/agenda-pembahasan-staff",
    element: StaffDiscussionAgenda,
    exact: true,
    type: "canView",
  },

  //Riwayat Manajemen Risiko
  {
    key: "risk-management-history",
    routeType: "private",
    path: "/riwayat-manajemen-risiko",
    element: RiskHistory,
    exact: true,
    type: "canView",
  },
  {
    key: "risk-management-history",
    routeType: "private",
    path: "/riwayat-manajemen-risiko/:year",
    element: RiskHistoryDetail,
    exact: true,
    type: "canView",
  },
];

export default routesName;
