import React, { useEffect, useMemo, useState } from "react";
import { Form, Button, Space, notification } from "antd";
import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlusOutlined,
  SaveOutlined,
} from "@ant-design/icons";
import "./index.scss";
import { NavLink, useParams } from "react-router-dom";
import Icon, { RightOutlined } from "@ant-design/icons";
import "react-quill/dist/quill.snow.css";

import { useNavigate } from "react-router-dom";
import { Input } from "components";
import {
  useRiskProfileHeader,
  useRiskProfileItem,
  useRiskProfileItemSingle,
  useStoreRiskProfile,
} from "hooks/useRiskProfile";
import RiskProfileForm from "./form";
import { includes, isEmpty, map, split, toLower } from "lodash";
import { BASE_URL_MANRISK } from "services/config";
import axiosInstance from "app/interceptors";

const HomeLine = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.66667 13.1667H12.3333M8.18141 1.30333L2.52949 5.69927C2.15168 5.99312 1.96278 6.14005 1.82669 6.32405C1.70614 6.48704 1.61633 6.67065 1.56169 6.86588C1.5 7.08627 1.5 7.32558 1.5 7.80421V13.8333C1.5 14.7667 1.5 15.2335 1.68166 15.59C1.84144 15.9036 2.09641 16.1585 2.41002 16.3183C2.76654 16.5 3.23325 16.5 4.16667 16.5H13.8333C14.7668 16.5 15.2335 16.5 15.59 16.3183C15.9036 16.1585 16.1586 15.9036 16.3183 15.59C16.5 15.2335 16.5 14.7667 16.5 13.8333V7.80421C16.5 7.32558 16.5 7.08627 16.4383 6.86588C16.3837 6.67065 16.2939 6.48704 16.1733 6.32405C16.0372 6.14005 15.8483 5.99312 15.4705 5.69927L9.81859 1.30333C9.52582 1.07562 9.37943 0.961766 9.21779 0.918001C9.07516 0.879384 8.92484 0.879384 8.78221 0.918001C8.62057 0.961766 8.47418 1.07562 8.18141 1.30333Z"
      stroke="#667085"
      strokeWidth="1.66667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const CreateKadivProbis = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const [removedId, setRemovedId] = useState([]);
  const [riskPriority, setRiskPriority] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const path = `${BASE_URL_MANRISK}/risk-priority/${id}`;

        const response = await axiosInstance.get(path);

        if (response.data.isSuccess) {
          setRiskPriority(response.data.payload);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  // fetch risk profile header info
  const header = useRiskProfileHeader(["fetchRiskProfileHeader", riskPriority?.idRiskProfileProbis], {
    refetchOnMount: true,
  });

  // fetch risk profile detail
  const riskProfile = useRiskProfileItemSingle(["fetchRiskProfileItemSingle", riskPriority?.idRiskProfileProbis], {
    refetchOnMount: true,
  });

  // mitigation mutation
  const postRiskProfile = useStoreRiskProfile();

  const riskProfileItems = useMemo(() => {
    return {
      riskProfileItems: [
        {
          event: null,
          reason: null,
          idAreaImpact: null,
          idImpactLevelCategory: null,
          idRiskCategory: null,
          heldControlSystem: null,
          isLowProbabilityType: null,
          probabilityRiskCriteriaType: null,
          idProbabilityLowFrequencyEvent: null,
          idProbabilityNonLowFrequencyEvent: null,
          idProbabilityLevel: null,
          probabilityDescription: null,
          idImpactLevel: null,
          impactDescription: null,
          idRiskSize: null,
          idRiskLevel: null,
          mitigationDecision: false,
          residualHope: {
            id: null,
            idProbabilityLevel: null,
            idImpactLevel: null,
            idRiskSize: null,
          },
          iru: {
            id: null,
            name: null,
            limitValueLower: null,
            limitValueMiddle: null,
            limitValueUpper: null,
          },
          notes: null,
        },
      ],
    };
  }, []);

  const onFinish = (values, type = "complete") => {
    postRiskProfile.mutate(
      {
        type,
        params: {
          ...values,
          riskProfileProbisId: id,
          deletedRiskProfileItems: removedId,
        },
      },
      {
        // error handling
        onError: (error) => {
          notification.error({
            message: "Kesalahan",
            description: error?.message,
          });
        },
        // success handling
        onSuccess: (data) => {
          if (data.isSuccess) {
            notification.success({
              message: data?.message,
              description: "Data telah disimpan",
            });

            window.location.replace("/profil-risiko-staff");
          } else {
            let message = data?.innerMessage ?? data?.message;
            if (includes(toLower(message), "batasan"))
              message =
                "Harap Periksa 'Batasan Nilai', Minimal 1 Batasan Terisi";
            notification.error({
              message: "Kesalahan",
              description: message,
            });
          }
        },
      }
    );
  };

  useEffect(() => {
    if (riskProfile.status === "success" && riskProfile) {
      if (!isEmpty(riskProfile.data?.payload)) {
        // set values if any
        form.setFieldsValue({
          riskProfileItems: [riskProfile.data?.payload]
        });
      }
    }
  }, [riskProfile.status]);

  const SubmitButton = () => (
    <Button
      className="btn-submit mt-4 ml-4"
      onClick={() => {
        form.submit();
      }}
      loading={postRiskProfile.isPending}
    >
      <span className="ml-2 text-center">Simpan</span>
      <Icon
        component={ArrowRightOutlined}
        className="mr-2"
        style={{ fontSize: 15 }}
      />
    </Button>
  );

  const onSaveDraft = async () => {
    try {
      const values = await form.getFieldsValue();
      onFinish(values, "draft");
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to save draft, please try again later.",
      });
    }
  };

  const DraftButton = () => (
    <Button
      icon={<SaveOutlined />}
      className="btn-back"
      onClick={onSaveDraft}
      loading={postRiskProfile.isPending}
    >
      <span className="ml-2 text-center">Simpan & Draft</span>
    </Button>
  );

  const onRemoveField = (remove, name) => {
    // get riskProfileItems value based on user clicked on
    const riskProfileItems = form.getFieldValue("riskProfileItems");
    if (!isEmpty(riskProfileItems[name]?.id)) {
      // take previous and merge removed mitigationitemplans id
      setRemovedId([...removedId, riskProfileItems[name]?.id]);
    }

    // Remove item UI
    remove(name);
  };

  return (
    <>
      <div className="px-4">
        <Form
          name="form-update-probis"
          form={form}
          onFinish={onFinish}
          initialValues={{
            riskProfileItems,
          }}
          scrollToFirstError={{
            behavior: "smooth",
            block: "center",
            inline: "center",
          }}
        >
          <div className="flex items-center py-5 flex-wrap">
            <Space>
              <Icon component={HomeLine} className="mr-2" />
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Dokumen Pendukung MR</p>
            </div>
            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium mr-3">Prioritas Risiko</p>
            </div>

            <Space>
              <RightOutlined className="mr-3 text-xs right-cursor" />
            </Space>
            <div>
              <p className="font-medium" style={{ color: "#01A24A" }}>
                Detail Prioritas Risiko
              </p>
            </div>
          </div>

          <div className="py-5">
            <p className="text-2xl font-medium">Detail Prioritas Risiko</p>
          </div>

          <div
            className="w-100 overflow-hidden mb-24"
            style={{
              borderRadius: "8px",
              border: "1px solid #EAECF0",
              background: "#FFFF",
            }}
          >
            <div className="">
              <div
                className=""
                style={{ background: "#00BD52", height: "10px" }}
              ></div>

              <div
                className="m-5 w-100 overflow-hidden mb-6"
                style={{
                  borderRadius: "8px",
                  border: "1px solid #EAECF0",
                  background: "#FFFF",
                }}
              >
                <div
                  className=""
                  style={{ background: "#00BD52", height: "10px" }}
                ></div>
                <div className="row px-5 pb-3 mt-4">
                  <label className="field-required font-medium">
                    Sasaran Organisasi
                  </label>
                  <Form.Item name={"riskProfileProbisId"} noStyle />
                  <Input
                    placeholder="Sasaran Organisasi"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled
                    value={header?.data?.payload?.organizationTargetName}
                  />
                </div>
                <div className="px-5 pb-3">
                  <label className="field-required font-medium">
                    Indikator Kinerja Utama
                  </label>

                  <Input
                    placeholder="Indikator Kinerja Utama"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled
                    value={header?.data?.payload?.ikuName}
                  />
                </div>

                <div className="px-5 pb-3">
                  <label className="field-required font-medium">
                    Sub Indikator Kinerja Utama
                  </label>

                  <Input
                    placeholder="Sub Indikator Kinerja Utama"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled
                    value={header?.data?.payload?.subIkuName ?? "-"}
                  />
                </div>

                <div className="px-5 pb-3">
                  <label className="field-required font-medium">Target</label>

                  <Input
                    placeholder="Target"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled
                    value={header?.data?.payload?.target ?? "-"}
                  />
                </div>

                <div className="px-5 pb-3">
                  <label className="field-required font-medium">
                    Divisi
                  </label>
                  <Input
                    placeholder="Divisi"
                    type="text"
                    className="form-input mt-2"
                    style={{ width: "100%" }}
                    disabled
                    value={header?.data?.payload?.kadivProbisName}
                  />
                </div>
              </div>

              <Form.List name="riskProfileItems">
                {(fields, { add, remove }) => (
                  <>
                    {fields?.map((field) => (
                      <>
                        <RiskProfileForm
                          key={field.name}
                          item={field}
                          fields={fields}
                          remove={remove}
                          form={form}
                          onRemoveField={onRemoveField}
                        />
                      </>
                    ))}
                  </>
                )}
              </Form.List>
            </div>
          </div>

          <div
            className="mt-6 px-4 rounded border border-[#EAECF0] bg-white"
            style={{
              position: "fixed",
              bottom: 0,
              width: "calc(100vw - 400px)",
            }}
          >
            <div className="flex justify-between items-center p-3">
              <Space>
                <NavLink to={"/prioritas-risiko"}>
                  <Button icon={<ArrowLeftOutlined />} className="btn-back">
                    <span className="ml-2 text-center">Kembali</span>
                  </Button>
                </NavLink>
              </Space>
              <div className="row"> 
              </div>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};

export default CreateKadivProbis;
