import { Form, Select, Row, Col, InputNumber } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./index.scss";
import { Input } from "components";
import TextArea from "antd/es/input/TextArea";
import {
  useAreaImpact,
  useAreaImpactLevel,
  useAreaImpactLevelCategory,
  useLowProbabilityOption,
  useNonLowProbabilityOption,
  useProbabilityLevel,
  useRiskCategory,
  useRiskLevel,
  useRiskSize,
} from "hooks/useMaster";
import { memo, useEffect, useMemo, useState } from "react";
import { find, isNil, map } from "lodash";

const RiskProfileForm = ({ form, item, fields, remove, onRemoveField }) => {
  const [selectedAreaImpact, setSelectedAreaImpact] = useState(null);
  const [selectedAreaImpactLevelCategory, setSelectedAreaImpactLevelCategory] =
    useState(null);
  const [selectedProbability, setSelectedProbability] = useState(null);
  const [selectedAreaImpactLevel, setSelectedAreaImpactLevel] = useState(null);

  const [selectedNonProbabilityType, setSelectedNonProbabilityType] =
    useState(null);
  const [selectedProbabilityOption, setSelectedProbabilityOption] =
    useState(null);

  const [selectedProbabilityLevel, setSelectedProbabilityLevel] =
    useState(null);
  const [selectedProbabilityImpactLevel, setSelectedProbabilityImpactLevel] =
    useState(null);

  // fetch master data
  const areaImpact = useAreaImpact(["fetchAreaImpact"], {
    refetchOnMount: true,
  });
  const riskCategory = useRiskCategory(["fetchRiskCategory"], {
    refetchOnMount: true,
  });
  const probabilityLevel = useProbabilityLevel(["fetchProbabilityLevel"], {
    refetchOnMount: true,
  });

  const areaImpactLevel = useAreaImpactLevel(
    [
      "fetchAreaImpactLevel",
      {
        IdAreaImpact: selectedAreaImpact,
        IdImpactLevelCategory: selectedAreaImpactLevelCategory,
      },
    ],
    {
      refetchOnMount: true,
      enabled: !!selectedAreaImpact,
    }
  );

  const areaImpactLevelCategory = useAreaImpactLevelCategory(
    ["fetchAreaImpactLevelCategory", { id: selectedAreaImpact }],
    {
      refetchOnMount: true,
      enabled: !!selectedAreaImpact,
    }
  );

  const lowProbabilityOption = useLowProbabilityOption(
    ["fetchLowProbabilityOption"],
    {
      refetchOnMount: true,
      enabled: !isNil(selectedProbability) && selectedProbability,
    }
  );

  const nonLowProbabilityOption = useNonLowProbabilityOption(
    [
      "fetchNonLowProbabilityOption",
      { NonLowType: selectedNonProbabilityType === "Frekuensi" ? 1 : 0 },
    ],
    {
      refetchOnMount: true,
      enabled:
        !isNil(selectedProbability) &&
        !selectedProbability &&
        !!selectedNonProbabilityType,
    }
  );

  const riskSize = useRiskSize(
    [
      "fetchRiskSize",
      {
        IdProbabilityLevel: selectedProbabilityOption,
        ImpactLevelEnum: find(areaImpactLevel?.data?.payload, {
          id: selectedAreaImpactLevel,
        })?.impactLevel,
      },
    ],
    {
      refetchOnMount: true,
      enabled: !!selectedProbabilityOption && !!selectedAreaImpactLevel,
    }
  );

  const riskLevel = useRiskLevel(
    ["fetchRiskLevel", { id: riskSize?.data?.payload?.idRiskLevel }],
    {
      refetchOnMount: true,
      enabled: !!riskSize?.data?.payload,
    }
  );

  const residualProbability = useRiskSize(
    [
      "fetchRiskSize",
      {
        IdProbabilityLevel: selectedProbabilityLevel,
        ImpactLevelEnum: find(areaImpactLevel?.data?.payload, {
          id: selectedProbabilityImpactLevel,
        })?.impactLevel,
      },
    ],
    {
      refetchOnMount: true,
      enabled: !!selectedProbabilityLevel && !!selectedProbabilityImpactLevel,
    }
  );

  const residualProbabilityLevel = useRiskLevel(
    ["fetchRiskLevel", { id: residualProbability?.data?.payload?.idRiskLevel }],
    {
      refetchOnMount: true,
      enabled: !!residualProbability?.data?.payload,
    }
  );

  const probabilityOption = useMemo(() => {
    if (!isNil(selectedProbability) && selectedProbability) {
      return map(lowProbabilityOption?.data?.payload, (val) => ({
        value: val.idProbabilityLevel,
        label: val?.description,
        parameter: val?.parameter,
        level: val?.level,
      }));
    }

    return map(nonLowProbabilityOption?.data?.payload, (val) => ({
      value: val.idProbabilityLevel,
      label: val?.description,
      parameter: val?.parameter,
      level: val?.level,
    }));
  }, [selectedProbability, lowProbabilityOption, nonLowProbabilityOption]);

  const probabilityLevelText = useMemo(() => {
    if (selectedProbabilityOption) {
      const opt = find(probabilityOption, {
        value: selectedProbabilityOption,
      });

      return `${opt?.parameter} (${opt?.level})`;
    }

    return "";
  }, [selectedProbabilityOption, probabilityOption]);

  const impactLevelText = useMemo(() => {
    if (selectedAreaImpactLevel) {
      const opt = find(areaImpactLevel?.data?.payload, {
        id: selectedAreaImpactLevel,
      });

      return `${opt?.impactLevelName} (${opt?.impactLevel})`;
    }

    return "";
  }, [selectedProbabilityOption, probabilityOption]);

  const impactRiskLevelText = useMemo(() => {
    const data = riskLevel?.data;
    return data?.payload
      ? `${data?.payload?.name ?? ""} (${data?.payload?.level ?? ""})`
      : "";
  }, [riskLevel]);

  const ResidualRiskLevelText = useMemo(() => {
    const data = residualProbabilityLevel?.data;
    return data?.payload
      ? `${data?.payload?.name ?? ""} (${data?.payload?.level ?? ""})`
      : "";
  }, [residualProbabilityLevel]);

  useEffect(() => {
    const data = form.getFieldValue("riskProfileItems");
    data[item.name] = {
      ...data[item.name],
      idRiskSize: riskSize?.data?.payload?.id,
      idRiskLevel: riskLevel?.data?.payload?.id,
      mitigationDecision: +riskSize?.data?.payload?.name >= 12 ? true : false,
    };
    form.setFieldValue("riskProfileItems", data);
  }, [riskLevel?.data?.payload]);

  useEffect(() => {
    const data = form.getFieldValue("riskProfileItems");
    data[item.name] = {
      ...data[item.name],
      residualHope: {
        ...data[item.name].residualHope,
        idRiskSize: residualProbability?.data?.payload?.id,
      },
    };
    form.setFieldValue("riskProfileItems", data);
  }, [residualProbability?.data?.payload]);

  useEffect(() => {
    const data = form.getFieldValue(["riskProfileItems", item.name]);

    if (data?.idAreaImpact) setSelectedAreaImpact(data?.idAreaImpact);
    if (data?.idImpactLevelCategory)
      setSelectedAreaImpactLevelCategory(data?.idImpactLevelCategory);

    setSelectedProbability(data?.isLowProbabilityType);
    if (data?.idProbabilityLevel)
      setSelectedProbabilityOption(data?.idProbabilityLevel);
    if (!data?.isLowProbabilityType)
      setSelectedNonProbabilityType(data?.probabilityRiskCriteriaType);
    if (data?.idImpactLevel) setSelectedAreaImpactLevel(data?.idImpactLevel);
    if (data?.residualHope?.idProbabilityLevel)
      setSelectedProbabilityLevel(data?.residualHope?.idProbabilityLevel);
    if (data?.residualHope?.idImpactLevel)
      setSelectedProbabilityImpactLevel(data?.residualHope?.idImpactLevel);
  }, []);

  // reset everything to default values if area impact is changed
  const onChangeImpactArea = (val) => {
    setSelectedAreaImpact(val);
    setSelectedAreaImpactLevelCategory(null);
    setSelectedProbability(null);
    setSelectedProbabilityOption(null);
    setSelectedNonProbabilityType(null);
    setSelectedAreaImpactLevel(null);
    setSelectedProbabilityLevel(null);
    setSelectedProbabilityImpactLevel(null);

    const data = form.getFieldValue("riskProfileItems");
    data[item.name] = {
      ...data[item.name],
      idImpactLevelCategory: null,
      isLowProbabilityType: null,
      probabilityRiskCriteriaType: null,
      idProbabilityLevel: null,
      probabilityDescription: null,
      idImpactLevel: null,
      impactDescription: null,
      idRiskSize: null,
      idRiskLevel: null,
      mitigationDecision: false,
      residualHope: {
        id: null,
        idProbabilityLevel: null,
        idImpactLevel: null,
        idRiskSize: null,
      },
      iru: {
        id: null,
        name: null,
        limitValueLower: null,
        limitValueMiddle: null,
        limitValueUpper: null,
      },
    };
    form.setFieldValue("riskProfileItems", data);
  };

  return (
    <div key={item.key} className="">
      <div className="m-5 w-100 overflow-hidden mb-6 rounded-lg border border-gray-200 bg-white">
        {/* start of "Risiko" section */}

        <div className="">
          <div className="bg-green-500 h-[10px]"></div>
          <div className="flex justify-between p-5">
            <p className="text-2xl font-medium">Risiko</p>
          </div>
          <hr />

          <div className="row px-5 mt-2">
            <Form.Item name={[item.name, "id"]} noStyle />
            <Row gutter={24} className="">
              <Col span={12}>
                <div className="">
                  <label className="field-required font-medium">No</label>
                  <Form.Item name={[item.name, "riskNumber"]}>
                    <Input
                      placeholder="No"
                      disabled
                      className="form-input mt-2 text-start w-full"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="">
                  <label className="field-required font-medium">Kejadian</label>
                  <Form.Item
                    name={[item.name, "event"]}
                    rules={[{ required: true, message: "Wajib Diisi" }]}
                  >
                    <TextArea
                      placeholder="Kejadian"
                      type="text"
                      className="form-input mt-2"
                      style={{ width: "100%" }}
                      disabled
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="">
                  <label className="field-required font-medium">Penyebab</label>
                  <Form.Item
                    name={[item.name, "reason"]}
                    rules={[{ required: true, message: "Wajib Diisi" }]}
                  >
                    <TextArea
                      placeholder="Penyebab"
                      type="text"
                      className="form-input mt-2 w-full"
                      disabled
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="">
                  <label className="field-required font-medium">
                    Klasifikasi Risiko
                  </label>
                  <Form.Item
                    name={[item.name, "isDownside"]}
                    rules={[{ required: true, message: "Wajib Diisi" }]}
                  >
                    <Select
                      disabled
                      placeholder="Klasifikasi Risiko"
                      type="text"
                      className="mt-2 w-full"
                      // onChange={onChangeImpactAreaCategory}
                    >
                      <Select.Option key={false} value={false}>
                        Upside
                      </Select.Option>
                      <Select.Option key={true} value={true}>
                        Downside
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="">
                  <label className="field-required font-medium">
                    Area Dampak
                  </label>
                  <Form.Item
                    name={[item.name, "idAreaImpact"]}
                    rules={[{ required: true, message: "Wajib Diisi" }]}
                  >
                    <Select
                      placeholder="Area Dampak"
                      type="text"
                      className="mt-2 w-full text-black"
                      onChange={onChangeImpactArea}
                      disabled
                    >
                      {areaImpact?.data?.payload?.map((item) => (
                        <Select.Option key={item.id} value={item.id}>
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="">
                  <label className="field-required font-medium">
                    Sub Area Dampak
                  </label>
                  <Form.Item name={[item.name, "idImpactLevelCategory"]}>
                    <Select
                      placeholder="Sub Area Dampak"
                      type="text"
                      className="mt-2 w-full"
                      disabled
                      onChange={setSelectedAreaImpactLevelCategory}
                    >
                      {areaImpactLevelCategory?.data?.payload?.map((item) => (
                        <Select.Option
                          key={item.id}
                          values={[item.id, item.name]}
                        >
                          {item.name}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <hr />
        <div className="flex justify-between items-center w-full gap-7 p-5">
          <div className="w-full">
            <label className="field-required font-medium">
              Kategori Risiko
            </label>
            <Form.Item
              name={[item.name, "idRiskCategory"]}
              style={{ width: "100%" }}
              rules={[{ required: true, message: "Wajib Diisi" }]}
            >
              <Select
                placeholder="Kategori Risiko"
                type="text"
                className="mt-2"
                style={{ width: "100%" }}
                disabled
              >
                {riskCategory?.data?.payload?.map((item) => (
                  <Select.Option key={item.id} value={item.id}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </div>

          <div className="w-full">
            <div>
              <label className="field-required font-medium">
                Sistem Pengendalian yang dilaksanakan
              </label>
              <Form.Item
                name={[item.name, "heldControlSystem"]}
                rules={[{ required: true, message: "Wajib Diisi" }]}
              >
                <TextArea
                  placeholder="Sistem Pengendalian yang dilaksanakan"
                  type="text"
                  className="form-input mt-2 w-full"
                  disabled
                />
              </Form.Item>
            </div>
          </div>
        </div>

        {/* end of "Risiko" section */}

        <hr />

        {/* start of "Kemungkinan" section */}

        <div className="w-full">
          <div className="bg-[#00BD52] h-[10px]"></div>
          <div className="">
            <div className="p-5">
              <p className="text-2xl font-medium">Kemungkinan</p>
            </div>
            <hr />

            <div className="row px-5 mt-2">
              <Row gutter={24} className="">
                <Col className="w-full">
                  <div className="flex justify-between items-center gap-7">
                    <div className="flex-1">
                      <label className="field-required font-medium">
                        Kriteria Kemungkinan
                      </label>
                      <Form.Item
                        name={[item.name, "isLowProbabilityType"]}
                        rules={[{ required: true, message: "Wajib Diisi" }]}
                      >
                        <Select
                          disabled
                          placeholder="Kriteria Kemungkinan"
                          type="text"
                          className="mt-2 w-full"
                          onChange={(value) => {
                            setSelectedProbability(value);
                            setSelectedProbabilityOption(null);
                            const data = form.getFieldValue("riskProfileItems");
                            data[item.name] = {
                              ...data[item.name],
                              probabilityRiskCriteriaType: undefined,
                              probabilityDescription: null,
                              idProbabilityLevel: null,
                            };
                            form.setFieldValue("riskProfileItems", data);
                          }}
                          options={[
                            {
                              value: true,
                              label: "Low Probability",
                            },
                            {
                              value: false,
                              label: "Non Low Probability",
                            },
                          ]}
                        />
                      </Form.Item>
                    </div>
                    {!selectedProbability && (
                      <div className="flex-1">
                        <label className="field-required font-medium">
                          Jenis Kriteria Kemungkinan
                        </label>
                        <Form.Item
                          name={[item.name, "probabilityRiskCriteriaType"]}
                          rules={[{ required: true, message: "Wajib Diisi" }]}
                        >
                          <Select
                            disabled
                            className="w-full mt-2"
                            placeholder="Jenis Kriteria Kemungkinan"
                            onChange={setSelectedNonProbabilityType}
                            options={[
                              {
                                value: "Frekuensi",
                                label: "Frekuensi",
                              },
                              {
                                value: "Probabilitas",
                                label: "Probabilitas",
                              },
                            ]}
                          />
                        </Form.Item>
                      </div>
                    )}
                  </div>
                </Col>

                <Col span={24}>
                  <Row className="" gutter={24}>
                    <Col span={12}>
                      <div className="">
                        <label className="field-required font-medium">
                          Kemungkinan Terjadinya Risiko
                        </label>

                        <Form.Item
                          name={[item.name, "idProbabilityLevel"]}
                          rules={[{ required: true, message: "Wajib Diisi" }]}
                        >
                          <Select
                            disabled
                            className="mt-2 w-full"
                            placeholder="Kemungkinan Terjadinya Risiko"
                            onChange={setSelectedProbabilityOption}
                            options={probabilityOption}
                          />
                        </Form.Item>
                      </div>
                    </Col>

                    <Col span={12}>
                      <div className="">
                        <label className="field-required font-medium">
                          Level Kemungkinan
                        </label>
                        <Input
                          disabled
                          placeholder="Level Kemungkinan"
                          type="text"
                          className="form-input mt-2 w-full"
                          defaultValue={probabilityLevelText}
                        />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col span={24}>
                      <div className="">
                        <label className="field-required font-medium">
                          Penjelasan
                        </label>
                        <Form.Item
                          name={[item.name, "probabilityDescription"]}
                          rules={[{ required: true, message: "Wajib Diisi" }]}
                        >
                          <TextArea
                            disabled
                            placeholder="Penjelasan"
                            type="text"
                            className="form-input mt-2 w-full"
                          />
                        </Form.Item>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {/* end of "Kemungkinan" section */}

        {/* start of "Dampak" section */}
        <div className="w-100 overflow-hidden">
          <div className="">
            <div
              className=""
              style={{
                background: "#00BD52",
                height: "10px",
              }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-2xl font-medium">Dampak</p>
            </div>
            <hr />

            <div className="row px-5 mt-2">
              <Row gutter={24} className="">
                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">Dampak</label>
                    <Form.Item
                      name={[item.name, "idImpactLevel"]}
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                    >
                      <Select
                        disabled
                        placeholder="Dampak"
                        type="text"
                        className="mt-2 w-full"
                        onChange={setSelectedAreaImpactLevel}
                      >
                        {areaImpactLevel?.data?.payload?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.description}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">
                      Level Dampak
                    </label>
                    <Input
                      disabled
                      placeholder="Level Dampak"
                      type="text"
                      className="form-input mt-2 w-full"
                      defaultValue={impactLevelText}
                    />
                  </div>
                </Col>
                <Col span={24}>
                  <div className="">
                    <label className="field-required font-medium">
                      Penjelasan
                    </label>
                    <Form.Item
                      name={[item.name, "impactDescription"]}
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                    >
                      <TextArea
                        disabled
                        placeholder="Deskripsi Level"
                        type="text"
                        className="form-input mt-2 w-full"
                      />
                    </Form.Item>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        <div className="flex justify-between items-center w-full gap-7 p-5">
          <div className="w-full">
            <label className="field-required font-medium">Besaran Risiko</label>
            <Form.Item name={[item.name, "idRiskSize"]} noStyle />
            <Input
              disabled
              placeholder="Besaran Risiko"
              type="text"
              className="form-input my-2 w-full"
              rules={[{ required: true, message: "Wajib Diisi" }]}
              defaultValue={riskSize?.data?.payload?.name ?? ""}
            />
          </div>

          <div className="w-full">
            <label className="field-required font-medium">Level Risiko</label>
            <Form.Item name={[item.name, "idRiskLevel"]} noStyle />
            <Input
              disabled
              placeholder="Level Risiko"
              type="text"
              className="form-input my-2 w-full"
              defaultValue={impactRiskLevelText}
            />
          </div>
        </div>

        <Row gutter={24} className="">
          <Col span={24}>
            <div className="w-100 overflow-hidden my-6">
              <div className="">
                <div
                  className=""
                  style={{ background: "#00BD52", height: "10px" }}
                ></div>
              </div>
              <div className="row m-5">
                <label className="field-required font-medium">
                  Keputusan Mitigasi
                </label>
                <Form.Item name={[item.name, "mitigationDecision"]} noStyle />
                <Input
                  disabled
                  placeholder="Keputusan Mitigasi"
                  type="text"
                  className="form-input my-2 w-full"
                  // defaultValue={+riskSize?.data?.payload?.name > 12 ? "Ya" : "Tidak"}
                  value={+riskSize?.data?.payload?.name >= 12 ? "Ya" : "Tidak"}
                />
              </div>
            </div>
          </Col>
        </Row>

        {/* end of "Dampak" section */}

        {/* start of "Risiko Residual Harapan" section */}

        <div className="w-100 overflow-hidden">
          <div className="">
            <div
              className=""
              style={{
                background: "#00BD52",
                height: "10px",
              }}
            ></div>
            <div className="px-5 py-5">
              <p className="text-2xl font-medium">Risiko Residual Harapan</p>
            </div>
            <hr />

            <div className="row px-5 pb-5 mt-2">
              <Row gutter={24} className="">
                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">
                      Level Kemungkinan
                    </label>
                    <Form.Item
                      name={[item.name, "residualHope", "idProbabilityLevel"]}
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                    >
                      <Select
                        disabled
                        placeholder="Level Kemungkinan"
                        type="text"
                        className="mt-2 w-full"
                        onChange={setSelectedProbabilityLevel}
                      >
                        {probabilityLevel?.data?.payload?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.parameter} ({item.level})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">
                      Level Dampak
                    </label>
                    <Form.Item
                      name={[item.name, "residualHope", "idImpactLevel"]}
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                    >
                      <Select
                        disabled
                        placeholder="Level Dampak"
                        type="text"
                        className="mt-2 w-full"
                        onChange={setSelectedProbabilityImpactLevel}
                      >
                        {areaImpactLevel?.data?.payload?.map((item) => (
                          <Select.Option key={item.id} value={item.id}>
                            {item.impactLevelName} ({item.impactLevel})
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">
                      Besaran Risiko
                    </label>
                    <Form.Item
                      name={[item.name, "residualHope", "idRiskSize"]}
                      noStyle
                    />
                    <Input
                      disabled
                      placeholder="Besaran Risiko"
                      type="text"
                      className="form-input mt-2 w-full"
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                      defaultValue={
                        residualProbability?.data?.payload?.name ?? ""
                      }
                    />
                  </div>
                </Col>

                <Col span={12}>
                  <div className="">
                    <label className="field-required font-medium">
                      Level Risiko
                    </label>
                    <Input
                      disabled
                      placeholder="Level Risiko"
                      type="text"
                      className="form-input mt-2 w-full"
                      rules={[{ required: true, message: "Wajib Diisi" }]}
                      defaultValue={ResidualRiskLevelText}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>

        {+riskSize?.data?.payload?.name >= 12 && (
          <div className="w-100 overflow-hidden mb-6">
            <div className="">
              <div
                className=""
                style={{
                  background: "#00BD52",
                  height: "10px",
                }}
              ></div>
              <div className="px-5 py-5">
                <p className="text-2xl font-medium">
                  Indikator Risiko Utama (IRU)
                </p>
              </div>
              <hr />

              <div className="row px-5 mt-2">
                <Row gutter={24} className="">
                  <Col span={24}>
                    <div className="">
                      <label className="field-required font-medium">Nama</label>
                      <Form.Item
                        name={[item.name, "iru", "name"]}
                        rules={[{ required: true, message: "Wajib Diisi" }]}
                      >
                        <TextArea
                          disabled
                          placeholder="Nama"
                          type="text"
                          className="form-input mt-2"
                        />
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24}>
                    <p className="text-lg font-medium mb-2">
                      Batasan Nilai{" "}
                      <small className="text-gray-500 text-xs">
                        (Minimal 1 Batasan Terisi)
                      </small>
                    </p>
                    <hr />
                  </Col>
                  <Col span={24}>
                    <Row gutter={10}>
                      <Col span={8}>
                        <div className="my-2">
                          <label className="font-medium">Batas Atas</label>
                          <Form.Item
                            name={[item.name, "iru", "limitValueUpper"]}
                            // rules={[{ required: true, message: "Wajib Diisi" }]}
                          >
                            <Input
                              disabled
                              placeholder="Batas Atas"
                              type="text"
                              className="form-input mt-2 w-full focus:outline-none"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="my-2">
                          <label className="font-medium">Batas Aman</label>
                          <Form.Item
                            name={[item.name, "iru", "limitValueMiddle"]}
                            // rules={[{ required: true, message: "Wajib Diisi" }]}
                          >
                            <Input
                              disabled
                              placeholder="Batas Aman"
                              type="text"
                              className="form-input mt-2 w-full focus:outline-none"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={8}>
                        <div className="my-2">
                          <label className="font-medium">Batas Bawah</label>
                          <Form.Item
                            name={[item.name, "iru", "limitValueLower"]}
                            // rules={[{ required: true, message: "Wajib Diisi" }]}
                          >
                            <Input
                              disabled
                              placeholder="Batas Bawah"
                              type="text"
                              className="form-input mt-2 w-full focus:outline-none"
                            />
                          </Form.Item>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </div>
          </div>
        )}

        {/* end of "Risiko Residual Harapan" section */}

        <div className="w-100 overflow-hidden mb-6">
          <div className="">
            <div
              className=""
              style={{
                background: "#00BD52",
                height: "10px",
              }}
            ></div>
          </div>

          <div className="row px-5 mt-4">
            <div className="">
              <label className="font-medium">Catatan</label>
              <Form.Item
                name={[item.name, "notes"]}
                // rules={[{ required: true, message: "Wajib Diisi" }]}
              >
                <TextArea
                  placeholder="Catatan"
                  type="text"
                  className="form-input mt-2"
                  disabled
                />
              </Form.Item>
            </div>
          </div>
        </div>
        {/* end of card */}
      </div>
      {fields.length > 1 && item.key !== fields.length - 1 && (
        <hr className="border border-green-500" />
      )}
    </div>
  );
};

export default memo(RiskProfileForm);
