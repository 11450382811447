import { Button, Pagination, Select, Space, Spin, Table, notification } from "antd";
import React, { useMemo, useState } from "react";
import Icon, { RightOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { HomeLined } from "components/atoms/SVG";
import { useRiskList } from "hooks/useRiskList";
import SearchInput from "components/molecules/SearchInput";
import { debounce, toLower } from "lodash";
import axiosInstance from "app/interceptors";
import { BASE_URL_MANRISK } from "services/config";
import mime from "mime";
import moment from "moment";

const RiskList = ({ year }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [keyword, setKeyword] = useState("");

  const riskRegister = useRiskList([
    "fetchRiskRegister",
    {
      Length: pageSize,
      Start: (currentPage - 1) * pageSize,
      Keyword: keyword,
    },
  ]);

  const totalRecords = useMemo(
    () => riskRegister?.data?.payload?.recordsTotal ?? 0,
    [riskRegister]
  );

  const handlePageSizeChange = (value) => {
    setPageSize(value);
    setCurrentPage(1);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const onSearch = debounce((e) => {
    setKeyword(toLower(e.target.value));
  }, 1000);

  const handleDownload = async () => {
    try {
      const response = await axiosInstance.get(`${BASE_URL_MANRISK}/risk-register/download`, {
        responseType: 'blob',
      });
      
      const ext = mime.getExtension(response.data.type) === 'bin' ? 'pdf' : mime.getExtension(response.data.type); 
      if (ext !== 'json'){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        const filename = `${moment(Date.now()).format("YYYYMMDDkkmmss")}-daftar-risiko.${ext}`;
        link.href = url;
        link.setAttribute('download', filename);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      }else{
        const errorMsg = JSON.parse(await response.data.text());
        notification.error({
          message: "Kesalahan",
          description: errorMsg.message,
        });
      }
      
    } catch (error) {
      console.error('There was an error!', error);
    }
  };

  const columns = useMemo(() => {
    const defaultColumns = [
      {
        title: "No",
        key: "number",
        width: "68px",
        height: "72px",
        render: (val, text, data) => {
          return <p>{data + 1}</p>;
        },
      },
      {
        title: "Sasaran Organisasi",
        dataIndex: "organizationTarget",
        key: "organizationTarget",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Kode RE",
        dataIndex: "reCodeNew",
        key: "reCodeNew",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Kejadian Risiko",
        dataIndex: "riskEvent",
        key: "riskEvent",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val || "-"}</p>;
        },
      },
      {
        title: "Awal Periode",
        dataIndex: "period",
        key: "period",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? 0}</p>;
        },
      },
      {
        title: "Residual Harapan",
        dataIndex: "residualHope",
        key: "residualHope",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? 0}</p>;
        },
      },
      {
        title: "TW1",
        dataIndex: "tw1",
        key: "tw1",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW2",
        dataIndex: "tw2",
        key: "tw2",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW3",
        dataIndex: "tw3",
        key: "tw3",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
      {
        title: "TW4",
        dataIndex: "tw4",
        key: "tw4",
        sortDirections: ["descend", "ascend"],
        render: (val, text, data) => {
          return <p>{val ?? "-"}</p>;
        },
      },
    ];
    return defaultColumns;
  }, []);
  return (
    <>
      <div className="flex items-center py-4 flex-wrap">
        <Space>
          <Icon component={HomeLined} className="mr-2" />
          <RightOutlined className="mr-3 text-xs right-cursor" />
        </Space>
        <div>
          <p className="font-medium" style={{ color: "#475467" }}>
            Daftar Risiko
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-medium">List Risiko</h1>
        <Space>
          <Button
            htmlType="submit"
            icon={<CloudDownloadOutlined />}
            className="btn-default mt-4"
            onClick={handleDownload}
          >
            <span className="ml-2 text-center">Download</span>
          </Button>
        </Space>
      </div>

      <div className="border border-gray-200 rounded-lg p-3 w-full">
        <div className="flex gap-4 pb-3">
          <div className="space-x-5 flex w-full">
            <SearchInput placeholder="Cari..." onChange={onSearch} />
          </div>
        </div>
        <>
          <Spin spinning={riskRegister.isLoading}>
            <Table
              columns={columns}
              dataSource={riskRegister?.data?.payload?.data}
              pagination={false}
              style={{ overflowX: "auto" }}
            />
          </Spin>
          <div className="flex table-paginator-wrapper my-3 px-2 justify-between">
            <div className="flex flex-row justify-content-between mb-2 px-2 justify-between items-center">
              <div className="show align-self-center pr-2 font-medium text-sm">
                {"Tampilkan Data"}
              </div>
              <div className="dropdown-paging align-self-center pr-2">
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Select
                    className="select-paging-ppd"
                    style={{ width: "60px" }}
                    options={[
                      { value: 5, label: 5 },
                      { value: 10, label: 10 },
                      { value: 30, label: 30 },
                      { value: 50, label: 50 },
                      { value: 100, label: 100 },
                    ]}
                    onChange={handlePageSizeChange}
                    value={pageSize}
                  />
                </Space>
              </div>
              <div className="border-paging align-self-center pr-2"></div>
              <div className="total align-self-center pr-2">
                {`Showing ${Math.min(
                  (currentPage - 1) * pageSize + 1,
                  totalRecords
                )} to ${Math.min(
                  currentPage * pageSize,
                  totalRecords
                )} of ${totalRecords} entries`}
              </div>
            </div>
            <div>
              <Pagination
                current={currentPage}
                pageSize={pageSize}
                total={totalRecords}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default RiskList;
